import React from "react"
import GlobalStyle from "../components/GlobalStyle"
import MenuBar from "../components/layout/MenuBar"
import BackgroundImage from "gatsby-background-image"
import DatePickerComponent from "../components/datePickerComponent/DatePickerComponent"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { device } from "../utils/mediaQueries"
import { CityCategories } from "../components/cityCategories/CityCategories"
import { Breadcrumbs } from "../components/layout/breadcrumbs/Breadcrumbs"
import { CvAd } from "../components/ads/cvAd"
import { OmaurakkaAd } from "../components/ads/omaUrakkaAd"

const Box = styled.div`
  margin: 50px;
  border-radius: 15px;
  padding: 0px;
  text-align: left;
  @media ${device.mobileL} {
    margin: 5px;
  }
`

const HeaderContainer = styled.div`
  padding: 0rem 1rem;
`

const Background = styled(BackgroundImage)`
  margin-bottom: 30px;
  padding-bottom: 30px;
`

const Header1 = styled.h1`
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  padding: 5px 20px;

  color: white;
  @media ${device.mobileL} {
    padding: 0.1rem;
    font-size: 35px;
  }
`
const Header2 = styled.h2`
  font-size: 27px;
  text-align: center;
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 5px 20px;
  color: white;
`

const ResultHeader = styled.h3`
  font-size: 22px;
  font-weight: 200;
  text-align: center;
`

const ResultContainer = styled.div`
  padding: 2rem;
`

const TextContainer = styled.div`
  padding: 50px 150px;
  @media ${device.mobileL} {
    padding: 15px;
  }
`

const RegionTemplate = ({ data, pageContext }) => {
  const { region } = pageContext
  const imageData = data.file.childImageSharp.fluid
  const thisPage = data.markdownRemark.frontmatter
  const cities = data.cities.edges
  const bulkCities = data.bulkCities.edges
  const regions = data.regions.edges

  return (
    <GlobalStyle>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.markdownRemark.frontmatter.metaTitle}</title>
        <meta
          name="description"
          content={data.markdownRemark.frontmatter.metaDesc}
        />
        <html lang="fi" />
      </Helmet>
      <Background Tag="section" fluid={imageData} backgroundColor={`#040e18`}>
        <MenuBar items={regions} />
        <HeaderContainer>
          <Box>
            <Header1>{thisPage.title}</Header1>
            <Header2>Etsi sopiva majoitus alapuolelta</Header2>
            {/* <FilterContainer>
              <CabinFilter
                title={"Montako henkilöä mökissä majoittuu?"}
                placeholder={"henkilöä"}
                action={cabinsHook.setPersons}
              />
            </FilterContainer> */}
            <DatePickerComponent
              urlFix={data.markdownRemark.frontmatter.urlFix}
              filter={data.markdownRemark.frontmatter.filter}
              name={data.markdownRemark.frontmatter.name}
              isRegion={true}
              bookingId={thisPage.bookingId}
            />
          </Box>
          <div></div>
        </HeaderContainer>
      </Background>
      <OmaurakkaAd />
      <CvAd />
      <ResultContainer>
        <Breadcrumbs />
        <ResultHeader>
          {cities.length > 0
            ? `Suosituimmat matkakohteet kohteessa ${data.markdownRemark.frontmatter.name}`
            : ""}
        </ResultHeader>
        <CityCategories cities={cities} />
        <CityCategories cities={bulkCities} bulk={true} />
      </ResultContainer>
      <TextContainer
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      ></TextContainer>
    </GlobalStyle>
  )
}
export const query = graphql`
  query($filter: String!, $path: String!, $img: String!, $region: String!) {
    file(base: { eq: $img }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        filter
        name
        img
        metaDesc
        metaTitle
        path
        title
        urlFix
        bookingId
      }
    }
    cities: allMarkdownRemark(
      filter: { frontmatter: { parent: { eq: $filter } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            filter
            name
            img
          }
        }
      }
    }
    bulkCities: allMongodbMokkivuokrausCities(
      filter: { region: { eq: $region }, parentCity: { eq: null } }
    ) {
      edges {
        node {
          city
          parentCity
          region
        }
      }
    }
    regions: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "region" } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            filter
            name
            img
          }
        }
      }
    }
  }
`
export default RegionTemplate
