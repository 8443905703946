import React from "react"
import styled from "styled-components"
import CvAdImage from "../images/CvAdImage"
const Container = styled.div`
  padding-left: 0.3rem;
  margin-top: 0.3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
`

const Ad = styled.div`
  padding: 0.1rem 0.2rem;
  background-color: blue;
  color: white;
  font-size: 0.7rem;
  border-radius: 3px;
  width: 70px;
  text-align: center;
  margin: 0rem auto;
`

const Text = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
`
const TextContainer = styled.div`
  padding: 0.3rem 2rem;
  margin: 0rem auto;
  text-align: center;
`

export const OmaurakkaAd = ({ data }) => {
  const trackClick = (label, category) => {
    typeof window !== "undefined" &&
      window.gtag("event", `ActionClick`, {
        event_label: label,
        event_category: category,
      })
  }
  return (
    <Container onClick={() => trackClick("adOmaurakka", "adClick")}>
      {/* <Link href="https://www.cvpohjat.fi/" target="_blank">
        <Image
          width="400px"
          height="192px"
          layout="responsive"
          src="/cv-pohjat.png"
        />
        <Box>
          <Tag colorScheme="green" marginRight="1rem" marginTop="0.3rem">
            Mainos
          </Tag> */}
      <a href="https://www.omaurakka.fi/" target="_blank">
        <TextContainer>
          <Ad>mainos</Ad>
          <Text>
            Kilpailuta paras tekijä remontille kuin remontille vain yhdellä hakemuksella - Omaurakka.fi
          </Text>
        </TextContainer>
      </a>
    </Container>
  )
}
